/* LMS Dashboard Styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1f3a5b;
    color: white;
    padding: 10px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .profile {
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-name {
    margin-right: 10px;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    color: #333;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Add more styles here based on previous example */
  