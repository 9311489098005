/* Overall page container */
.policy-container {
    background: linear-gradient(135deg, #FFD59E 0%, #FFA45B 100%);
    min-height: 100vh;
    padding: 50px 20px;
    color: #fff;
    font-family: 'Arial', sans-serif;
  }
  
  /* Section styling */
  .policy-section {
    max-width: 800px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    color: #333;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Section content */
  .policy-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    background: linear-gradient(135deg, #FFA45B 0%, #FFD59E 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .policy-section p {
    line-height: 1.8;
    margin-bottom: 15px;
  }
  
  .policy-section ul {
    list-style: disc;
    margin: 15px 0 15px 20px;
    line-height: 1.8;
  }
  
  /* Hyperlink styling */
  .policy-section a {
    color: #FF7F50;
    text-decoration: none;
    font-weight: bold;
  }
  
  .policy-section a:hover {
    text-decoration: underline;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .policy-section {
      padding: 20px;
    }
  
    .policy-section h1 {
      font-size: 2rem;
    }
  }
  