/* General Styles */
body {
    font-family: Arial, sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3 {
    color: #333;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
  }
  
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .hero-content p {
    font-size: 1.2rem;
  }
  
  /* Courses Section */
  .courses-section {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .courses-section h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .courses-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .course-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .course-card {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .course-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .course-card p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .course-card button {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .course-card button:hover {
    background-color: #0056b3;
  }
  
  /* Contact Section */
  .contact-section {
    padding: 40px 20px;
    background: #e8f5e9;
  }
  
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
  }
  
  .contact-info, .contact-form {
    flex: 1 1 45%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-info h2, .contact-form h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .contact-info p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .contact-info a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .contact-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  .contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .contact-form button {
    display: inline-block;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  /* Mobile Responsiveness */
  @media screen and (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-info, .contact-form {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  