/* Modal Overlay */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Darker transparent background */
  z-index: 1000;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
}

/* Modal Content */
.modal-content {
  background: linear-gradient(145deg, #f7f7f7, #e0e0e0); /* Gradient background */
  padding: 60px 40px 40px 40px; /* Added more padding at the top to avoid overlap */
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
  width: 80%;
  max-width: 600px; /* Responsive and centered */
  position: relative; /* Ensure the close button is positioned relative to this */
  transition: transform 0.3s ease-out;
  box-sizing: border-box; /* Include padding in width/height calculation */
}

/* Header Style */
.modal h2 {
  margin-top: 0;
  font-size: 28px; /* Larger header font size */
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 25px; /* Increased margin to create space between heading and form */
  text-transform: uppercase; /* Make the title pop */
  letter-spacing: 1px;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 15px; /* Reduced space from top */
  right: 15px; /* Reduced space from right */
  padding: 8px; /* Smaller padding */
  background-color: transparent;
  border: none;
  color: #ff6347;
  font-size: 18px; /* Smaller font size */
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease-in-out;
}

.close-button:hover {
  color: #ff4500; /* Darker coral for hover effect */
  transform: rotate(90deg); /* Close button rotates on hover */
}

/* Form Layout */
.modal form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input Fields */
.modal input,
.modal select,
.modal textarea {
  padding: 14px 18px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: #333;
  transition: box-shadow 0.3s ease, transform 0.2s ease-in-out;
}

/* Input Focus Effect */
.modal input:focus,
.modal select:focus,
.modal textarea:focus {
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.7); /* Blue focus outline */
  transform: scale(1.02); /* Slight zoom-in effect on focus */
}

/* Textarea Styling */
.modal textarea {
  resize: none;
  min-height: 120px; /* Taller textarea */
}

/* Submit Button */
.modal button {
  padding: 12px 24px;
  background-color: #ff6347; /* Vibrant coral color */
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease, transform 0.2s ease-in-out;
}

.modal button:hover {
  background-color: #ff4500; /* Darker coral on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Responsiveness */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    padding: 30px;
  }
  .modal h2 {
    font-size: 24px;
    margin-bottom: 20px; /* Adjusted margin */
  }
  .modal input,
  .modal select,
  .modal textarea {
    font-size: 14px;
  }
  .modal button {
    font-size: 16px;
    padding: 10px 20px;
  }
}
/* this new  */

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.confirmation-content {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.confirmation-content p {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.confirmation-content button {
  margin: 0 0.5rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirmation-content button:first-child {
  background-color: #007bff;
  color: white;
}

.confirmation-content button:first-child:hover {
  background-color: #0056b3;
}

.confirmation-content button:last-child {
  background-color: #dc3545;
  color: white;
}

.confirmation-content button:last-child:hover {
  background-color: #a71d2a;
}
